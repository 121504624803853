<template>
    <div style="overflow-x: hidden">
        <ul class="nav nav-tabs mt-1 mb-3" id="myTab" role="tablist" v-if="reserve?.business_proposal?.length">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                <li class="nav-item" role="presentation">
                    <a class="nav-link" :class="{ active: actionNext === 0 }"
                       @click="actionNext = 0" id="details-tab"
                       data-toggle="tab" href="#details" role="tab" aria-controls="details"
                       aria-disabled="true">{{ t('ZOOP.SALES.VIEW_DETAILS') }}</a>
                </li>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                <li class="nav-item" role="presentation">
                    <a class="nav-link" :class="{ active: actionNext === 1}"
                       @click="actionNext = 1"
                       id="configEmission-tab" data-toggle="tab" href="#configEmission" role="tab"
                       aria-controls="visualizarProposata"
                       aria-disabled="true">{{ t('PROPOSAL.VIEW_PROPOSAL') }}</a>
                </li>
            </div>
        </ul>
        <div class="tab-content modal-row2 modal-height-max " v-if="isConfirm">
            <div class="tab-pane fade" :class="{ active: actionNext === 0, show: actionNext === 0 }" id="details"
                 role="tabpanel" aria-labelledby="details-tab">
                <div class="modal-body ">
                    <div class="row gutters">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="form-group" v-if="properties">
                                <label for="allotment">{{ t('LOTS.ALLOTMENT') }}: </label>
                                <select class="form-control selectpicker" v-model="properties.allotment_id" disabled>
                                    <option :value="properties.allotments.id">{{ properties.allotments.name }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row gutters">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <div class="form-group" v-if="reserve">
                                <label for="reserveType">{{ t('NAV.RESERVE_TYPES') }}: </label>
                                <select class="form-control selectpicker" v-model="reserve.reserve_type_id" disabled>
                                    <option :value="reserve.reserve_type.id">{{ reserve.reserve_type.name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                            <div class="form-group" v-if="reserve">
                                <label for="realtor">{{ t('RESERVE.REALTOR') }}: </label>
                                <select class="form-control selectpicker" v-model="reserve.realtor_id" disabled>
                                    <option :value="reserve.realtor_id">{{ reserve.realtor_name }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row gutters">
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6">
                            <div class="form-group" v-if="properties">
                                <label for="block">{{ t('LOTS.BLOCK') }}: </label>
                                <select class="form-control selectpicker" v-model="properties.block" disabled>
                                    <option :value="properties.block">{{ properties.block }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6">
                            <div class="form-group" v-if="properties">
                                <label for="lot">{{ t('LOTS.LOT') }}: </label>
                                <select class="form-control selectpicker" v-model="properties.id" disabled>
                                    <option :value="properties.id">{{ properties.name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                            <div class="form-group" v-if="reserve && reserve.reserve_duration">
                                <label for="reserveDuration">{{ t('RESERVE.RESERVE_DURATION') }}: </label>
                                <input class="form-control text-center" type="text"
                                       v-model.lazy="reserve.reserve_duration"
                                       v-money3="configDuration" disabled>
                            </div>
                        </div>
                        <div class="col-xl-5 col-lg-5 col-md-5" v-if="reserve?.brokerage_data">
                            <div class="form-group">
                                <label for="brokerage">{{ t('ALLOTMENT.BROKERAGE_VALUE') }}: </label>
                                <div class="input-group">
                                    <input class="form-control" type="text" id="brokerage" name="brokerage"
                                           disabled
                                           v-model="reserve.brokerage_value" v-money3="configBrokerage">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mt-2">
                        <div class="w-100 d-flex justify-content-between">
                            <label class="align-self-end">{{ t('CLIENTS.INDEX_TITLE') }}:</label>
                            <button class="btn btn-primary mb-1" v-if="!reserve?.business_proposal?.length"
                                    @click.stop.prevent="setAddClient">
                                <i class="icon-circle-with-plus"></i></button>
                        </div>

                        <div class="card-custom background-header" id="clients"
                             :style="isMissingClients ? {'border-color': '#d64c36'}: ''">
                            <div class="row gutters ml-1" style="min-height: 40px">
                                <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 d-flex">
                                    <label class="align-self-center">{{ t('COMPANIES.TAXPAYER') }}: </label>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 d-flex">
                                    <label class="align-self-center">{{ t('GENERAL.NAME') }}: </label>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 d-flex">
                                    <label class="align-self-center">{{ t('GENERAL.PHONE') }}: </label>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 d-flex">
                                    <label class="align-self-center">{{ t('GENERAL.EMAIL') }}: </label>
                                </div>
                            </div>
                            <div class="background-table padding border-top" v-for="(client, key) in clients"
                                 :key="key">
                                <div class="row gutters alin-text" style="margin-left: 0">
                                    <div class="'col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                        <p class="form-control" style="border: none">{{ client.taxpayer }}</p>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                        <div class="form-group">
                                            <p>{{ client.name }}</p>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-7 col-sm-7 col-7">
                                        <div class="form-group">
                                            <vue-tel-input
                                                    mode="national"
                                                    v-if="client?.phones[0]?.number"
                                                    v-model="client.phones[0].number"
                                                    disabled
                                                    :autoFormat="true"
                                                    :preferredCountries="['US', 'DE', 'FR', 'CN']"
                                                    :defaultCountry="client?.phones[0]?.code_country ? parseInt(client.phones[0].code_country) : 55"
                                                    :dropdownOptions="dropdownOptions"
                                                    :inputOptions="inputOptions"
                                            ></vue-tel-input>
                                            <p v-else>-</p>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                        <div class="form-group">
                                            <p>{{ client.email }}</p>
                                        </div>
                                    </div>
                                    <div class="col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 d-flex justify-content-end">
                                        <a class="bg-btn pl-2 pr-2 pb-1 pt-1 text-right rounded"
                                           v-if="!reserve?.business_proposal?.length"
                                           :title="t('ACTIONS.DELETE')"
                                           @click.stop.prevent="deleteClient(key)">
                                            <i class="icon-trash"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="background-table padding-null border-top text-center" v-if="!clients[0]">
                                <p>{{ t('GENERAL.MSG.NONE_COSTUMER_SELECTED') }}</p>
                            </div>
                        </div>
                        <div v-if="isMissingClients" class="validation mt-1">{{
                                t('GENERAL.MSG.CLIENT_REQUIRED')
                            }}
                        </div>
                    </div>
                    <div class="row gutters mt-custom" v-if="reserve">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="form-group">
                                <label for="observation">{{ t('GENERAL.COMMENTS') }}: </label>
                                <textarea class="form-control" id="observation" rows="3" v-model="reserve.observation"
                                          disabled></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="reserve?.business_proposal" class="tab-pane fade"
                 :class="{ active: actionNext === 1, show: actionNext === 1 }" id="business_proposal"
                 role="tabpanel" aria-labelledby="business_proposal-tab" style="margin-top: -20px">
                <div class="modal-body modal-row2 " style="overflow-x: hidden; min-height: 60vh">
                    <div class="card">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-hover table-bordered"
                                       style="overflow: hidden; min-height: 130px">
                                    <thead>
                                    <tr>
                                        <th class="text-center">N°</th>
                                        <th class="text-center">{{ this.t('PROPOSAL.INFO') }}</th>
                                        <th class="text-center">{{ this.t('PROPOSAL.CLIENT') }}</th>
                                        <th class="text-center">{{ this.t('PROPOSAL.STATUS') }}</th>
                                        <th class="text-center">{{ this.t('PROPOSAL.REALTOR') }}</th>
                                        <th class="text-center">{{ this.t('PROPOSAL.DATE_CREATE') }}</th>
                                        <th class="text-center">{{ this.t('PROPOSAL.END_DATE') }}</th>
                                        <th class="text-center">Assinatura</th>
                                        <th class="text-center">
                                            <p>Ações</p>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tr v-for="(iten, key) in reserve?.business_proposal" :key="key">
                                        <td class="text-center">{{ getNumberProposal(iten) }}</td>
                                        <td class="text-center">
                                            <div class="d-flex w-100 justify-content-center align-items-center">
                                                <div class="dropdown position-absolute"
                                                     @mouseout="setModalHoover(`dropdownMenuModal${key}`)"
                                                     @mouseover="setModalHoover(`dropdownMenuModal${key}`)">
                                                    <i class="icon-info-with-circle"
                                                       :id="`dropdownMenuModal${key}`"
                                                       data-toggle="dropdown"
                                                       aria-haspopup="true"
                                                       aria-expanded="false"></i>
                                                    <div class="dropdown-menu p-2 w-auto"
                                                         :aria-labelledby="`dropdownMenuModal${key}`"
                                                         style="min-width: 30rem;">
                                                        <div class="row mb-2 p-2">
                                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                                                <div class="font-weight-bolder mt-2 ml-4 text-left">
                                                                    {{ this.t('PROPOSAL.PARCEL_CONFIG.TYPE') }}
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                                <div class="font-weight-bolder mt-2 ml-4 text-left">
                                                                    {{ this.t('PROPOSAL.PARCEL_CONFIG.VALUE') }}
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                                <div class="font-weight-bolder mt-2 text-left">
                                                                    {{
                                                                        this.t('PROPOSAL.PARCEL_CONFIG.NUMBER_PARCELS')
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-for="(parcel, key) in iten?.parcel_configs" :key="key"
                                                             :class="key != 0 ? 'border-top2': ''">
                                                            <div class="row mw-100 space-between mt-2">
                                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                                    <div class="list-primary">
                                                                        <div class="w-auto">
                                                                            {{ parcel.name }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                                    <div class="list-primary">
                                                                        <div class="w-auto">
                                                                            {{ toCurrency(parcel.value) }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                                    <div class="list-primary">
                                                                        <div class="w-auto">
                                                                            {{ parcel.number_parcels }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-center">{{ getNameClient(reserve) }}</td>
                                        <td class="text-center">
                                            <div class="font-weight-bold align-self-center d-flex w-100 justify-content-center"
                                                 style="margin-bottom: -2px; width: 110px; text-align: justify"
                                                 id="status">
                                            <span class="rounded-pill pb-1 pt-1 text-center card pl-2 pr-2"
                                                  :style="{'background-color': translateColorStatus(iten.status) ,
                                                'color': 'white'}">
                                                {{ this.translateStatus(iten.status) }}
                                            </span>
                                            </div>
                                        </td>
                                        <td class="text-center"> {{
                                                reserve?.realtor_data?.name ? reserve.realtor_data.name : reserve?.realtor_name ? reserve?.realtor_name : 'Indefinido'
                                            }}
                                        </td>
                                        <td class="text-center"> {{ endDateFormat(iten.created_at, true) }}</td>
                                        <td class="text-center"> {{ endDateFormat(iten.end_date) }}</td>
                                        <td class="text-center">
                                            <div v-if="iten?.click_sign_documents?.status"
                                                 :style="{'background-color': translateColorStatusAssign(iten?.click_sign_documents?.status), 'margin-bottom': '0'}"
                                                 class=" card text-white font-weight-bold p-1"
                                                 style="font-size: 12px">
                                                {{ getStatusClickSing(iten?.click_sign_documents?.status) }}
                                            </div>
                                            <div v-else style="font-size: 12px"
                                            >{{ getStatusClickSing(iten?.click_sign_documents?.status) }}
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <div>
                                                <a type="button" data-toggle="dropdown" aria-haspopup="true"
                                                   aria-expanded="false"
                                                   style="font-size: 20px" id="listDropdown">
                                                    <i class="icon-more-horizontal" title="Opções"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-right dropdown-black">
                                                    <div class="dropdown-item pointer text-white"
                                                         @click="reprintProposal(iten)">
                                                        <i class="icon-eye1 mr-2 font-15"/>{{ t('PROPOSAL.VIEW') }}
                                                    </div>
                                                    <div class="dropdown-item pointer text-white"
                                                         v-if="iten.status === 'EM AVALIAÇÃO'"
                                                         @click="aproveProposal(iten, key)">
                                                        <i class="icon-thumbs-up mr-2 font-15"/>{{
                                                            t('PROPOSAL.APROVE')
                                                        }}
                                                    </div>
                                                    <div class="dropdown-item pointer text-white"
                                                         v-if="(iten.status === 'EM AVALIAÇÃO' || iten.status === 'APROVADA') && (!iten.click_sign_documents?.clicksign_id || (iten.click_sign_documents?.status !== 2 && iten.click_sign_documents?.status !== 6))"
                                                         @click="setModalRejectProposal(iten)">
                                                        <i class="icon-thumbs-down mr-2 font-15"/>{{ t('PROPOSAL.REJECT') }}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tbody>
                                    <slot name="tableBody"></slot>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer flex-row-reverse justify-content-between">
                <div class="right">
                    <button data-cy="reserve_modal_confirm_confirm" type="button" class="btn btn-secondary mr-1"
                            data-dismiss="modal" @click="$emit('close')">{{ t('ACTIONS.CLOSE') }}
                    </button>
                    <button v-if="actionNext === 1"
                            data-cy="reserve_modal_confirm_close" type="button" class="btn btn-secondary mr-1"
                            @click="actionNext = 0">{{ t('ACTIONS.BACK') }}
                    </button>
                    <button v-if="reserve?.business_proposal?.length && actionNext === 0"
                            data-cy="reserve_modal_confirm_close" type="button" class="btn btn-primary mr-1"
                            @click="actionNext = 1">{{ t('ACTIONS.NEXT') }}
                    </button>
                    <button v-else data-cy="reserve_modal_confirm_close" type="button" class="btn btn-primary mr-1"
                            @click="confirm(this.reserveIndex.id)">{{ t('ACTIONS.CONFIRM') }}
                    </button>
                </div>
                <div class="left">
                    <div v-if="reserve?.business_proposal?.length" class="red">
                        <p v-if="actionNext === 1">Necessário ter uma proposta ativa para confirmar a reserva</p>
                        <p v-if="actionNext === 0">Não é possivel adicionar clientes a reservas que contenham
                            propostas</p>
                    </div>
                </div>
            </div>
        </div>
        <modalRejectProposal position="two" :proposal-index="proposalReject" :modal-instance="this.modalRejectInstance" @proposalRejected="proposalRejected"></modalRejectProposal>
        <transition name="fade">
            <div class="modal-mask" v-if="addClient">
                <div class="modal-wrapper" data-backdrop="static" data-keyboard="false">
                    <div class="modal-dialog lg" role="document" style="margin-top: 0rem;">
                        <div class="modal-content">
                            <div class="modal-header modal-header-color">
                                <h4 class="modal-title">Adicionar Cliente</h4>
                                <button type="button" class="closeX" id="closeX" @click="closeModalClient">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div>
                                <div class="modal-body mt-2" style="max-height: calc(100vh - 200px)">
                                    <div class="row gutters">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                            <div class="form-group">
                                                <label for="taxpayer">{{ t('COMPANIES.TAXPAYER') }}: </label>
                                                <input type="text"
                                                       id="taxpayer"
                                                       name="taxpayer"
                                                       v-model="client.taxpayer"
                                                       class="form-control"
                                                       v-mask="['###.###.###-##', '##.###.###/####-##']"
                                                       @keyup="inputTaxpayer(client.taxpayer)"
                                                       @blur="searchClient(client)"
                                                       placeholder="000.000.000-00"
                                                       :disabled="reserve?.business_proposal?.length">
                                                <div v-if="isTaxpayerInvalid" class="validation">{{
                                                        t(msgTaxpayer)
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                            <div class="form-group">
                                                <label for="brock">{{ t('GENERAL.NAME') }}: </label>
                                                <input type="text"
                                                       id="name"
                                                       name="name"
                                                       v-model="client.name"
                                                       class="form-control"
                                                       @keyup="inputName(client.name)"
                                                       placeholder="Nome Completo"
                                                       :disabled="reserve?.business_proposal?.length || !client.taxpayer">
                                                <div v-if="isNameInvalid" class="validation">{{ t(msgError) }}</div>
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                            <div class="form-group">
                                                <label for="name">{{ t('GENERAL.PHONE') }}: </label>
                                                <vue-tel-input
                                                        v-model="phones.number"
                                                        :autoFormat="true"
                                                        :preferredCountries="['US', 'DE', 'FR', 'CN']"
                                                        mode="national"
                                                        :disabled="reserve?.business_proposal?.length || !client.taxpayer"
                                                        :defaultCountry="phones.code_country ? parseInt(phones.code_country) : 55"
                                                        @country-changed="changeCountry"
                                                        :dropdownOptions="dropdownOptions"
                                                        :inputOptions="inputOptions"
                                                ></vue-tel-input>
                                                <div class="validation" id="invalidphone" style="display: none">{{
                                                        t(msgPhone)
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                            <div class="form-group">
                                                <label for="phone">{{ t('GENERAL.EMAIL') }}: </label>
                                                <input type="tel"
                                                       id="clientEmail"
                                                       name="clientEmail"
                                                       class="form-control"
                                                       placeholder="E-mail"
                                                       v-model="client.email"
                                                       :disabled="reserve?.business_proposal?.length || !client.taxpayer">
                                                <div class="validation" id="invalidEmail" v-if="msgEmail">{{
                                                        t(msgEmail)
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer justify-content-end">
                                <button class="btn btn-secondary mr-4" type="button"
                                        @click.stop.prevent="closeModalClient">{{
                                        t("ACTIONS.CLOSE")
                                    }}
                                </button>
                                <button data-cy="user_index_add_phone_save" class="btn btn-primary" type="button"
                                        @click.stop.prevent="selectClient(client)">{{ t("ACTIONS.SAVE") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

import {useI18n} from "vue-i18n";
import Clients from '@/services/Clients';
import {Money3Directive} from 'v-money3';
import Reserve from "@/services/Reserves";
import {useToast} from "vue-toastification";
import Allotments from '@/services/Allotments';
import Validate from '@/components/validate.vue';
import Permission from '@/components/permission.vue';
import Proposal from "../../services/Proposal";
import {VueTelInput} from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import {Modal} from 'bootstrap';
import modalRejectProposal from '../proposal/modalRejectProposal';
import StatusProposal from '../../mixins/StatusProposal';

export default {
    name: 'confirmReserve',
    mixins: [Validate, Permission, StatusProposal],
    props: ['reserveIndex', 'modalTitle', 'isConfirm', 'isEmailRequired', 'isDataRequired'],
    directives: {
        money3: Money3Directive
    },
    components: {
        VueTelInput,
        modalRejectProposal,
    },
    data() {
        return {
            modalRejectInstance: null,
            proposalReject: {motive_reject:null},
            dropdownOptions: {
                showDialCodeInList: true,
                showDialCodeInSelection: true,
                showFlags: true,
                showSearchBox: true,
            },
            inputOptions: {
                'aria-describedby': 'des',
                showDialCode: false,
                placeholder: '',
                tabindex: 1050,
            },
            addClient: false,
            client: {},
            isEmailNull: false,
            phones: {number: '', code_country: 55, country_acronym: 'BR'},
            clients: [],
            invalid: [],
            isNew: false,
            reserve: null,
            histories: [],
            clientsIds: [],
            properties: null,
            isRequiredDataClient: null,
            isRequiredEmail: null,
            isMissingClients: false,
            actionNext: 0,
            configEditor: {
                height: '200'
            },
            configBrokerage: {
                min: 0,
                suffix: '',
                masked: true,
                decimal: ',',
                precision: 2,
                disabled: false,
                thousands: '.',
                allowBlank: false,
                disableNegative: true,
                prefix: 'R$ ',
                max: 100000000000,
                minimumNumberOfCharacters: 1,
            },
            configDuration: {
                min: 0,
                max: 999,
                masked: true,
                decimal: ':',
                precision: 2,
                disabled: false,
                allowBlank: false,
                disableNegative: true,
                minimumNumberOfCharacters: 0
            },
        }
    },
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    mounted() {
        this.$store.commit('changeLoading', true);
        this.reserve = this.reserveIndex;
        if (this.reserve.brokerage_data) {
            let brokerage = JSON.parse(this.reserve.brokerage_data);
            this.reserve.brokerage_value = parseFloat(brokerage.brokerage_value).toFixed(2);
        }
        this.properties = this.reserve.properties;
        this.resetClient();
        this.startConfirm();
    },
    watch: {
        reserveIndex: function () {
            document.getElementById('clients').style.backgroundColor = '';
            this.actionNext = 0;
            this.reserve = this.reserveIndex;
            if (this.reserve.brokerage_data) {
                let brokerage = JSON.parse(this.reserve.brokerage_data);
                this.reserve.brokerage_value = parseFloat(brokerage.brokerage_value).toFixed(2);
            }
            this.properties = this.reserve.properties;
            this.resetClient();
            this.startConfirm();
        },
        isEmailRequired: function () {
            this.isRequiredEmail = this.isEmailRequired;
        },
        isDataRequired: function () {
            this.isRequiredDataClient = this.isDataRequired;
        },
    },
    methods: {
        setModalRejectProposal(item) {
            const modalElement = document.getElementById('modalShow');
            new Modal(modalElement).show();
            this.modalRejectInstance = new Modal(modalElement);
            this.modalRejectInstance.show()
            this.proposalReject = item;
            this.modalReject = true;
        },
        proposalRejected(iten){
            this.reserve.business_proposal.forEach(i => {
                if (i.id === iten.id){
                    i.status = 'REJEITADA'
                }
            })
            this.$store.commit('changeLoading', false);
        },
        changeCountry(val) {
            this.phones.code_country = val.dialCode
            this.phones.country_acronym = val.iso2
        },
        closeModalClient() {
            if (this.isTaxpayerInvalid) {
                return;
            }
            this.addClient = false;
            this.client = {
                phones: [
                    {
                        code_country: 55,
                        country_acronym: 'BR',
                        type: 1,
                        number: '',
                        description: null,
                    }
                ]
            }
            this.phones.number = '';
        },
        setAddClient() {
            this.addClient = true;
        },
        getNumberProposal(iten) {
            if (!iten?.properties?.id && this.reserve?.properties?.id) {
                iten.properties = this.reserve.properties;
            }
            let block = '';
            if (iten.properties?.block) {
                block = iten.properties.block.replace(/^0+/, '');
            }
            let lot = '';
            if (iten.properties?.name) {
                lot = iten.properties.name.replace(/^0+/, '');
            }
            if (iten.created_at) {
                let date = iten.created_at?.split('T');
                date = date[1]?.split(':');
                let sec = date[2]?.split('.')
                return `${block}${lot}${date[0]}${date[1]}${sec[0]}`;
            }
            return `${block}${lot}`;
        },
        toCurrency(value) {
            if (value === null) return 'Indisponível';
            return Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(value);
        },
        setModalHoover(id) {
            document.getElementById(id).click();
        },
        updateClient(client) {
            this.$store.commit('changeLoading', true);
            Clients.update(client).then(() => {
                this.toast.success(this.t('GENERAL.MSG.UPDATE_SUCCESS'));
                this.$store.commit('changeLoading', false);
            }).catch((err) => {
                this.$store.commit('changeLoading', false);
                this.errorMsg(err)
            })
        },
        rejectProposal(item, key) {
            let id = item.id
            if (item?.click_sign_documents?.id) {
                this.$swal.fire({
                    title: `Assinatura será cancelada ao rejeitar a proposta N°${this.getNumberProposal(item)}`,
                    text: 'Deseja prosseguir?',
                    showCancelButton: true,
                    showCloseButton: true,
                    confirmButtonText: `Sim`,
                    cancelButtonText: 'Não'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.commit('changeLoading', true);
                        Proposal.rejectProposal(id).then(() => {
                            this.reserve.business_proposal = this.reserve.business_proposal.filter((i, k) => k !== key);
                            if (!this.reserve.business_proposal?.length) this.actionNext = 0;
                            this.toast.success(this.t('PROPOSAL.REJECTE_SUCCESS'));
                            this.$store.commit('changeLoading', false);
                        }).catch(() => {
                            this.$store.commit('changeLoading', false);
                        })
                    }
                });
                return;
            } else {
                this.$swal.fire({
                    title: `Realmente deseja rejeitar a proposta N°${this.getNumberProposal(item)} ?`,
                    text: '',
                    showCancelButton: true,
                    showCloseButton: true,
                    confirmButtonText: `Sim`,
                    cancelButtonText: 'Não'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.commit('changeLoading', true);
                        Proposal.rejectProposal(id).then(() => {
                            this.reserve.business_proposal = this.reserve.business_proposal.filter((i, k) => k !== key);
                            if (!this.reserve.business_proposal?.length) this.actionNext = 0;
                            this.toast.success(this.t('PROPOSAL.REJECTE_SUCCESS'));
                            this.$store.commit('changeLoading', false);
                        }).catch(() => {
                            this.$store.commit('changeLoading', false);
                        })
                    }
                });
            }
        },
        aproveProposal(item, key) {
            let id = item.id
            this.$swal.fire({
                title: `Realmente deseja aprovar a proposta N°${this.getNumberProposal(item)} ?`,
                text: '',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: `Sim`,
                cancelButtonText: 'Não'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.commit('changeLoading', true);
                    Proposal.aproveProposal(id).then((resp) => {
                        if (resp?.data?.reserve?.property?.allotments?.confirm_reserve_when_approve_business_proposal){
                            this.toast.success('Proposta aprovada e reserva confirmada com sucesso!');
                            document.getElementById('closeX').click();
                            this.$emit('confirm');
                            this.resetClient()
                            this.$store.commit('changeLoading', false);
                        }else {
                            this.reserve.business_proposal[key].status = 'APROVADA';
                            this.reserve.business_proposal = this.reserve.business_proposal.filter((i, k) => k === key || i.status === 'APROVADA');
                            this.toast.success(this.t('PROPOSAL.APROVE_SUCCESS'));
                            this.$store.commit('changeLoading', false);
                        }
                    }).catch(() => {
                        this.$store.commit('changeLoading', false);
                    })
                }
            });
        },
        reprintProposal(iten) {
            this.$store.commit('changeLoading', true);
            Proposal.reprintProposal(iten.id).then(resp => {
                this.$store.commit('changeLoading', false);
                let win = window.open();
                win.document.write('' +
                        '<iframe src="' + resp.data + '"' +
                        'frameborder="0" ' +
                        'style="width:100vw; height:100vh; margin: -0.6rem;"' +
                        'allow="fullscreen">' +
                        '</iframe>'
                );
            }).catch(() => {
                this.$store.commit('changeLoading', false)
            })
        },
        getNameClient(iten) {
            let name = '';
            let clients = iten?.reserves_clients
            clients.forEach((item, key) => {
                if (clients[key + 1]) {
                    name += item.clients.name + ' ,';
                }
                name += item.clients.name;
            })
            return name;
        },
        endDateFormat(value, created = false) {
            if (value && !created) {
                let dateAndTime = value.split(' ');
                let date = dateAndTime[0].split('-');
                let time = dateAndTime[1].split(':');
                return `${date[2]}/${date[1]}/${date[0]} às ${time[0]}:${time[1]}`;
            } else if (value && created) {
                let dateAndTime = value.split('T');
                let date = dateAndTime[0].split('-');
                let time = dateAndTime[1].split(':');
                return `${date[2]}/${date[1]}/${date[0]} às ${time[0]}:${time[1]}`;
            }
            return 'Indefinido'
        },
        startConfirm() {
            if (this.isConfirm) {
                this.clients = [];
                this.clientsIds = [];
                this.indexAllotment(this.properties?.allotment_id);
                this.reserve.reserves_clients.forEach(reserve_client => {
                    this.clients.push(reserve_client.clients);
                    this.clientsIds.push({id: reserve_client.clients.id});
                });
            }
        },
        indexAllotment(allotment_id) {
            this.$store.commit('changeLoading', true);
            Allotments.show(allotment_id).then(resp => {
                if (resp.data.reserve_duration_max) {
                    this.reserve.reserve_duration = resp.data.reserve_duration_max;
                } else {
                    this.reserve.reserve_duration = '00:00';
                }
                this.$store.commit('changeLoading', false);
            }).catch(error => {
                this.errorMsg(error)
                this.$store.commit('changeLoading', false);
            });
        },
        confirm(reserve_id) {
            if (this.clientsIds[0]) {
                this.isMissingClients = false;
                if (this.clients?.length && this.isRequiredEmail) {
                    let next = true;
                    this.clients.forEach(item => {
                        if (!item.email) {
                            this.toast.error(this.t('ALLOTMENT.RESERVE_HAS_CLIENT_WITHOUT_EMAIL'));
                            next = false;
                            return;
                        }
                    })
                    if (!next) {
                        return
                    }
                }
                if (this.checkSingularClient()) return;
                this.$store.commit('changeLoading', true);
                let reserve = {clients: this.clientsIds};
                let allotment_id = this.properties.allotments.id;
                Reserve.confirm(reserve_id, reserve, allotment_id).then(() => {
                    document.getElementById('closeX').click();
                    this.toast.success(this.t('GENERAL.MSG.RESERVE_CONFIRM_SUCCESSED'));
                    this.$emit('confirm');
                    this.resetClient()
                    this.$store.commit('changeLoading', false);
                }).catch(error => {
                    this.$store.commit('changeLoading', false);
                    this.errorMsg(error);
                });
            } else {
                this.isMissingClients = true;
            }
        },
        searchClient(client) {
            this.invalid = [];
            this.validateTaxpayer(client.taxpayer);
            if (this.invalid.length == 0) {
                this.$store.commit('changeLoading', true);
                const tax = Buffer.from(client.taxpayer).toString('base64');
                Clients.showByTaxpayer(tax).then(resp => {
                    if (resp.data.id) {
                        if (!resp.data?.phones?.length) {
                            resp.data.phones = [{
                                code_country: this.phones?.code_country ? parseInt(this.phones.code_country) : 55,
                                country_acronym: this.phones?.country_acronym ? this.phones.country_acronym : 'BR',
                                type: 1,
                                number: '',
                                description: null
                            }];
                        }
                        this.isEmailNull = !resp.data.email;
                        this.client = resp.data;
                        this.phones = resp.data.phones[0];
                        this.inputName(this.client.name);
                    } else {
                        this.isNew = true;
                    }
                    this.$store.commit('changeLoading', false);
                })

            }
        },
        deleteClient(position) {
            this.clients.splice(position, 1);
            this.clientsIds.splice(position, 1);
        },
        checkSingularClient() {
            let hasClient = false;
            if (this.client.taxpayer) hasClient = true;
            if (this.client.name) hasClient = true;
            if (this.client?.phones?.length && this.client.phones[0]?.number) hasClient = true;
            if (hasClient) {
                document.getElementById('clients').style.backgroundColor = 'rgba(233,142,142,0.3)';
                this.toast.error(this.t('RESERVE.ADD_CLIENT_OR_CLEAR'));
            } else {
                document.getElementById('clients').style.backgroundColor = '';
            }
            return hasClient;
        },
        selectClient(client) {
            if (this.isNew) {
                client.phones = [{
                    type: 1,
                    number: this.phones.number,
                    code_country: this.phones?.code_country ? parseInt(this.phones.code_country) : 55,
                    country_acronym: this.phones?.country_acronym ? this.phones.country_acronym : 'BR',
                }]
            }
            if (this.checkClient(client)) {
                if (!this.clients.find(c => c.taxpayer === client.taxpayer)) {
                    this.$store.commit('changeLoading', true)
                    if (client.id) {
                        if (this.isRequiredEmail && this.isEmailNull) {
                            client.simpleInsert = true;
                            Clients.update(client).then(() => {
                                this.clients.push(client);
                                this.clientsIds.push({id: client.id});
                                this.resetClient();
                                this.closeModalClient();
                            }).catch((err) => {
                                this.errorMsg(err)
                            })
                        } else {
                            this.clients.push(client);
                            this.clientsIds.push({id: client.id});
                            this.resetClient();
                            this.closeModalClient();
                        }
                        Reserve.attachClient(client, this.reserve.id).then(() => {
                            this.toast.success(this.t('RESERVE.CLIENT_ATTACHED_TO_RESERVE'));
                            this.$store.commit('changeLoading', false);
                        }).finally(() => this.$store.commit('changeLoading', false));
                    } else {
                        client.simpleInsert = true;
                        Clients.create(client).then(resp => {
                            Reserve.attachClient(resp.data, this.reserve.id).then(() => {
                                this.toast.success(this.t('RESERVE.CLIENT_ATTACHED_TO_RESERVE'));
                                this.$store.commit('changeLoading', false);
                            }).finally(() => this.$store.commit('changeLoading', false));
                            this.clients.push(resp.data);
                            this.clientsIds.push({id: resp.data.id});
                            this.resetClient();
                            this.closeModalClient();
                        }).catch((err) => {
                            this.errorMsg(err)
                        });
                    }
                    this.isMissingClients = false;
                    document.getElementById('clients').style.backgroundColor = '';
                } else {
                    this.toast.error(this.t('GENERAL.MSG.PREVIOUSLY_REGISTERED_CUSTOMER_INFORMATION'), {timeout: false});
                }
            }
        },
        resetClient() {
            this.client = {};
            this.phones = {
                number: '',
                code_country: this.phones.code_country ? parseInt(this.phones.code_country) : 55,
                country_acronym: this.phones.country_acronym ? this.phones.country_acronym : 'BR',
            };
            document.getElementById('name')?.classList?.remove('is-valid');
            document.getElementById('phone')?.classList?.remove('is-valid');
            document.getElementById('name')?.classList?.remove('is-invalid');
            document.getElementById('phone')?.classList?.remove('is-invalid');
            document.getElementById('taxpayer')?.classList?.remove('is-valid');
            document.getElementById('taxpayer')?.classList?.remove('is-invalid');
        },
        checkClient(client) {
            this.invalid = [];
            this.nameValidate(client.name);
            if (!client.taxpayer) {
                document.getElementById('taxpayer').classList.add("is-invalid");
                this.isTaxpayerInvalid = true;
                this.msgTaxpayer = "GENERAL.MSG.REQUIRED_FIELD";
            } else {
                document.getElementById('taxpayer').classList.remove("is-invalid");
                this.isTaxpayerInvalid = false;
                this.msgTaxpayer = null;
            }
            if (this.isRequiredEmail && !client?.email?.length) {
                document.getElementById('clientEmail').classList.add("is-invalid");
                this.msgEmail = "GENERAL.MSG.REQUIRED_FIELD";
                return false;
            } else {
                document.getElementById('clientEmail').classList.remove("is-invalid");
                this.msgEmail = null;
            }
            if (this.invalid.length && this.invalid.length > 0) {
                return false;
            }
            if (this.isTaxpayerInvalid) {
                return;
            }
            return true;
        },
        observationChange(observation) {
            this.reserve.observation = observation;
        },
    }
}
</script>

<style scoped>
.custom-control.custom-switch .custom-control-label::after {
    top: 4px !important;
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: #385a4a;
}

.border-hoover {
    border: none;
}

.border-hoover:hover {

}

.right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end !important;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
}

.name {
    margin: auto !important;
    border-radius: 3px;
    padding-bottom: 1px;
    padding-top: 6px;
}

.secondary {
    border-radius: 3px;
    color: #ffffff !important;
    background-color: #383737;
}

.full-border {
    border-radius: 2px !important;
    border-bottom: 1px solid #dbd4d4 !important;
    border-top: 1px solid #dbd4d4 !important;
    border-left: 1px solid #dbd4d4 !important;
    border-right: 1px solid #dbd4d4 !important;
}

.background {
    color: #333333 !important;
    background: #f6f8fa !important;
}

.close-custom {
    color: #000 !important;
}

.modal-title {
    padding-bottom: 1rem;
}

.value {
    font-size: .8rem;
    font-weight: 400;
}

.custom-margin {
    height: 24px !important;
}

.width-buttons {
    width: 30%;
}

.margin-historic {
    padding-top: 1rem;
    padding-left: 1rem;
}

.modal-header {
    padding-bottom: 0em !important;
}

.modal-body {
    padding-top: 0em !important;
}

.card-info {
    padding: 0.5rem;
    font-size: .840rem;
}

.user-card {
    padding-top: 0.1em !important;
    padding-bottom: 0.5em !important;
}

.alin-between {
    justify-content: space-between !important;
}

.hide-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.right-content {
    justify-content: flex-end;
}

.padding {
    padding: 0.4rem;
}

.background-header {
    background-color: #eeeeee;
}

.background-table {
    background-color: #fff;
}

.card-custom {
    border-radius: 3px;
    margin-bottom: 1rem;
    border: 1px solid #d7dbe2;
    border-bottom: 1px solid #d7dbe2;
}

.border-top {
    border-top: 1px solid #dee2e6;
}

.padding-null {
    padding: 0.75rem;
}

.alin-text {
    align-items: center;
}

.btn-custom {
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition-duration: 0.4s;
}

.bg-btn {
    background-color: white !important;
    color: black !important;
}

.bg-btn:hover {
    background-color: #0e5e4e !important;
    color: white !important;
}

.modal {
    position: absolute;
}

@media (max-width: 810px) {
    ::v-deep .vti__dropdown-list {
        width: 47vw;
        max-width: 350px;
    }
}
</style>
