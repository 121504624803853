<template>
    <div>
        <div class="modal-row2 modal-height-max" id="modalListByReserve">
            <div class="modal-body modal-row2 mt-4" style="overflow-x: hidden; min-height: 60vh;">
                <div class="card">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-hover table-bordered" style="overflow: hidden; min-height: 130px">
                                <thead>
                                <tr>
                                    <th class="text-center">N°</th>
                                    <th class="text-center">{{ this.t('PROPOSAL.INFO') }}</th>
                                    <th class="text-center">{{ this.t('PROPOSAL.CLIENT') }}</th>
                                    <th class="text-center">{{ this.t('PROPOSAL.STATUS') }}</th>
                                    <th class="text-center">{{ this.t('PROPOSAL.REALTOR') }}</th>
                                    <th class="text-center">{{ this.t('PROPOSAL.DATE_CREATE') }}</th>
                                    <th class="text-center">{{ this.t('PROPOSAL.END_DATE') }}</th>
                                    <th class="text-center">Assinatura</th>
                                    <th class="text-center">
                                        <p>Ações</p>
                                    </th>
                                </tr>
                                </thead>
                                <tr v-for="(iten, key) in reserve?.business_proposal" :key="key">
                                    <td class="text-center">{{ getNumberProposal(iten) }}</td>
                                    <td class="text-center">
                                        <div class="d-flex w-100 justify-content-center align-items-center">
                                            <div class="dropdown position-absolute"
                                                 @mouseout="setModalHoover(`dropdownMenuModal${key}`)"
                                                 @mouseover="setModalHoover(`dropdownMenuModal${key}`)">
                                                <i class="icon-info-with-circle"
                                                   :id="`dropdownMenuModal${key}`"
                                                   data-toggle="dropdown"
                                                   aria-haspopup="true"
                                                   aria-expanded="false"></i>
                                                <div class="dropdown-menu p-2 w-auto"
                                                     :aria-labelledby="`dropdownMenuModal${key}`"
                                                     style="min-width: 30rem;">
                                                    <div class="row mb-2 p-2">
                                                        <div class="col-12" v-if="iten.motive_reject && iten.status === 'REJEITADA'">
                                                            <div class="form-group">
                                                                <label>Motivo da rejeição:</label>
                                                                <div class=" pl-2 p-1 border">
                                                                    <p >{{iten.motive_reject}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12" v-if="iten.motive_reject">
                                                            <div class="form-group">
                                                                <label>Parcelas:</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                                            <div class="font-weight-bolder mt-2 ml-4 text-left">
                                                                {{ this.t('PROPOSAL.PARCEL_CONFIG.TYPE') }}
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                            <div class="font-weight-bolder mt-2 ml-4 text-left">
                                                                {{ this.t('PROPOSAL.PARCEL_CONFIG.VALUE') }}
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                            <div class="font-weight-bolder mt-2 text-left">
                                                                {{ this.t('PROPOSAL.PARCEL_CONFIG.NUMBER_PARCELS') }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-for="(parcel, key) in iten?.parcel_configs" :key="key"
                                                         :class="key != 0 ? 'border-top2': ''">
                                                        <div class="row mw-100 space-between mt-2">
                                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                                <div class="list-primary">
                                                                    <div class="w-auto">
                                                                        {{ parcel.name }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                                <div class="list-primary">
                                                                    <div class="w-auto">
                                                                        {{ toCurrency(parcel.value) }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                                <div class="list-primary">
                                                                    <div class="w-auto">
                                                                        {{ parcel.number_parcels }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center">{{ getNameClient(reserve) }}</td>
                                    <td class="text-center">
                                        <div class="font-weight-bold align-self-center d-flex w-100 justify-content-center"
                                             style="margin-bottom: -2px; width: 110px; text-align: justify" id="status">
                                            <span class="rounded-pill pb-1 pt-1 text-center card pl-2 pr-2"
                                                  :style="{'background-color': translateColorStatus(iten.status) ,
                                                'color': 'white'}">
                                                {{ this.translateStatus(iten.status) }}
                                            </span>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        {{ reserve?.realtor_data?.name ? reserve.realtor_data.name : 'Indefinido' }}
                                    </td>
                                    <td class="text-center"> {{ endDateFormat(iten.created_at, true) }}</td>
                                    <td class="text-center">
                                        {{timeExpirationNow[iten.id]?.countdown ? timeExpirationNow[iten.id]?.countdown : 'Indefinido' }}
                                    </td>
                                    <td class="text-center">
                                        <div v-if="iten?.click_sign_documents?.status"
                                             :style="{'background-color': translateColorStatusAssign(iten?.click_sign_documents?.status), 'margin-bottom': '0'}"
                                             class=" card text-white font-weight-bold p-1"
                                             style="font-size: 12px">
                                            {{ getStatusClickSing(iten?.click_sign_documents?.status) }}
                                        </div>
                                        <div v-else style="font-size: 12px"
                                        >{{ getStatusClickSing(iten?.click_sign_documents?.status) }}
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <div>
                                            <a type="button" data-toggle="dropdown" aria-haspopup="true"
                                               aria-expanded="false"
                                               style="font-size: 20px" id="listDropdown">
                                                <i class="icon-more-horizontal" title="Opções"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right dropdown-black">
                                                <div class="dropdown-item pointer text-white"
                                                     v-if="iten.status === 'EM AVALIAÇÃO' && !isRealtor()"
                                                     @click="aproveProposal(iten.id)">
                                                    <i class="icon-thumbs-up mr-2 font-15"/>{{
                                                        t('PROPOSAL.APROVE')
                                                    }}
                                                </div>
                                                <div class="dropdown-item pointer text-white"
                                                     @click="reprintProposal(iten)">
                                                    <i class="icon-eye1 mr-2 font-15"/>{{ t('PROPOSAL.VIEW') }}
                                                </div>
                                                <div class="dropdown-item pointer text-white"
                                                     v-if="(iten.status === 'EM AVALIAÇÃO' || iten.status === 'APROVADA') && !isRealtor() && (!iten.click_sign_documents?.clicksign_id || (iten.click_sign_documents?.status !== 2 && iten.click_sign_documents?.status !== 6))"
                                                     @click="setModalRejectProposal(iten)">
                                                    <i class="icon-thumbs-down mr-2 font-15"/>{{ t('PROPOSAL.REJECT') }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tbody>
                                <slot name="tableBody"></slot>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <modalRejectProposal position="two" :proposal-index="proposalReject" :modal-instance="this.modalRejectInstance" @proposalRejected="proposalRejected"></modalRejectProposal>
            <div class="modal-footer flex-row-reverse justify-content-between">
                <div class="right">
                    <button data-cy="reserve_modal_confirm_confirm" type="button" class="btn btn-secondary mr-1"
                            data-dismiss="modal" @click="$emit('close')">{{ t('ACTIONS.CLOSE') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {useI18n} from "vue-i18n";
import {useToast} from "vue-toastification";
import Validate from '@/components/validate.vue';
import Permission from '@/components/permission.vue';
import Proposal from "../../services/Proposal";
import widthMd from '../../mixins/widthMd';
import isReator from '../../mixins/isReator';
import modalRejectProposal from '../proposal/modalRejectProposal';
import { Modal } from 'bootstrap';
import StatusProposal from '../../mixins/StatusProposal';
import updateCountdownProposal from '../../mixins/updateCountdownProposal';

export default {
    name: 'proposalListByReserveComponent',
    mixins: [Validate, Permission, widthMd, isReator, StatusProposal,updateCountdownProposal],
    props: ['reserveIndex', 'modalTitle'],
    components:{
        modalRejectProposal,
    },
    data() {
        return {
            reserve: null,
            modalRejectInstance: null,
            proposalReject: {motive_reject:null},
            timeExpirationNow: {},
            intervalExpiration: {},
        }
    },

    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    beforeUnmount() {
        Object.values(this.intervalExpiration)?.forEach((interval) => {
            clearInterval(interval);
        });
    },
    mounted() {
        Object.values(this.intervalExpiration)?.forEach((interval) => {
            clearInterval(interval);
        });
        this.reserve = this.reserveIndex;
        this.reserve?.business_proposal?.forEach((i) =>{
            this.intervalExpiration[i.id] = setInterval(() => this.updateCountdown(i), 100);
        })
    },

    watch: {
        reserveIndex: function () {
            Object.values(this.intervalExpiration)?.forEach((interval) => {
                clearInterval(interval);
            });
            this.reserve = this.reserveIndex;
            this.reserve?.business_proposal?.forEach((i) =>{
                this.intervalExpiration[i.id] = setInterval(() => this.updateCountdown(i), 100);
            })
        },
    },

    methods: {
        proposalRejected(iten){
            this.reserve.business_proposal.forEach(i => {
                if (i.id === iten.id){
                    i.status = 'REJEITADA'
                }
            })
            this.$store.commit('changeLoading', false);
        },
        setModalRejectProposal(item) {
            const modalElement = document.getElementById('modalShow');
            new Modal(modalElement).show();
            this.modalRejectInstance = new Modal(modalElement);
            this.modalRejectInstance.show()
            this.proposalReject = item;
            this.modalReject = true;
        },
        getNumberProposal(iten) {
            let block = '';
            if (iten.properties?.block) {
                block = iten.properties.block.replace(/^0+/, '');
            }
            let lot = '';
            if (iten.properties?.name) {
                lot = iten.properties.name.replace(/^0+/, '');
            }
            if (iten.created_at) {
                let date = iten.created_at?.split('T');
                date = date[1]?.split(':');
                let sec = date[2]?.split('.')
                return `${block}${lot}${date[0]}${date[1]}${sec[0]}`;
            }
            return `${block}${lot}`;
        },
        toCurrency(value) {
            if (value === null) return 'Indisponível';
            return Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(value);
        },
        setModalHoover(id) {
            document.getElementById(id).click();
        },
        rejectProposal(item, key) {
            let id = item.id
            if (item?.click_sign_documents?.id) {
                this.$swal.fire({
                    title: `Assinatura será cancelada ao rejeitar a proposta N°${this.getNumberProposal(item)}`,
                    text: 'Deseja prosseguir?',
                    showCancelButton: true,
                    showCloseButton: true,
                    confirmButtonText: `Sim`,
                    cancelButtonText: 'Não'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.commit('changeLoading', true);
                        Proposal.rejectProposal(id).then(() => {
                            this.reserve.business_proposal = this.reserve.business_proposal.filter((i, k) => k !== key);
                            if (!this.reserve.business_proposal?.length)
                                this.toast.success(this.t('PROPOSAL.REJECTE_SUCCESS'));
                            this.$store.commit('changeLoading', false);
                        }).catch(() => {
                            this.$store.commit('changeLoading', false);
                        })
                    }
                });
                return;
            } else {
                this.$swal.fire({
                    title: `Realmente deseja rejeitar a proposta N°${this.getNumberProposal(item)} ?`,
                    text: '',
                    showCancelButton: true,
                    showCloseButton: true,
                    confirmButtonText: `Sim`,
                    cancelButtonText: 'Não'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.commit('changeLoading', true);
                        Proposal.rejectProposal(id).then(() => {
                            this.reserve.business_proposal = this.reserve.business_proposal.filter((i, k) => k !== key);
                            if (!this.reserve.business_proposal?.length)
                                this.toast.success(this.t('PROPOSAL.REJECTE_SUCCESS'));
                            this.$store.commit('changeLoading', false);
                        }).catch(() => {
                            this.$store.commit('changeLoading', false);
                        })
                    }
                });
            }
        },
        aproveProposal(id) {
            this.$swal.fire({
                title: `Realmente deseja aprovar a proposta ?`,
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: `Sim`,
                cancelButtonText: 'Não',
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.commit('changeLoading', true);
                    Proposal.aproveProposal(id).then((resp) => {
                        if (resp?.data?.reserve?.property?.allotments?.confirm_reserve_when_approve_business_proposal) {
                            this.toast.success('Proposta aprovada e reserva confirmada com sucesso!');
                        }else {
                            this.toast.success(this.t('PROPOSAL.APROVE_SUCCESS'));
                        }
                        document.getElementById('closeX').click();
                        this.$store.commit('changeLoading', false);
                    }).catch((err) => {
                        this.$store.commit('changeLoading', false);
                        this.errorMsg(err);
                    })
                }
            });
        },
        reprintProposal(iten) {
            this.$store.commit('changeLoading', true);
            Proposal.reprintProposal(iten.id).then(resp => {
                this.$store.commit('changeLoading', false);
                let win = window.open();
                win.document.write('' +
                        '<iframe src="' + resp.data + '"' +
                        'frameborder="0" ' +
                        'style="width:100vw; height:100vh; margin: -0.6rem;"' +
                        'allow="fullscreen">' +
                        '</iframe>'
                );
            }).catch(() => {
                this.$store.commit('changeLoading', false)
            })
        },
        getNameClient(iten) {
            let name = '';
            let clients = iten?.reserves_clients
            clients.forEach((item, key) => {
                if (clients[key + 1]) {
                    name += item.clients.name + ' ,';
                }
                name += item.clients.name;
            })
            return name;
        },
        endDateFormat(value, created = false) {
            if (value && !created) {
                let dateAndTime = value.split(' ');
                let date = dateAndTime[0].split('-');
                let time = dateAndTime[1].split(':');
                return `${date[2]}/${date[1]}/${date[0]} às ${time[0]}:${time[1]}`;
            } else if (value && created) {
                let dateAndTime = value.split('T');
                let date = dateAndTime[0].split('-');
                let time = dateAndTime[1].split(':');
                return `${date[2]}/${date[1]}/${date[0]} às ${time[0]}:${time[1]}`;
            }
            return 'Indefinido'
        },
    }
}
</script>

<style>
.custom-control.custom-switch .custom-control-label::after {
    top: 4px !important;
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: #385a4a;
}

.border-hoover {
    border: none;
}

.border-hoover:hover {

}

.right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end !important;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
}

.name {
    margin: auto !important;
    border-radius: 3px;
    padding-bottom: 1px;
    padding-top: 6px;
}

.secondary {
    border-radius: 3px;
    color: #ffffff !important;
    background-color: #383737;
}

.full-border {
    border-radius: 2px !important;
    border-bottom: 1px solid #dbd4d4 !important;
    border-top: 1px solid #dbd4d4 !important;
    border-left: 1px solid #dbd4d4 !important;
    border-right: 1px solid #dbd4d4 !important;
}

.background {
    color: #333333 !important;
    background: #f6f8fa !important;
}

.close-custom {
    color: #000 !important;
}

.modal-title {
    padding-bottom: 1rem;
}

.value {
    font-size: .8rem;
    font-weight: 400;
}

.custom-margin {
    height: 24px !important;
}

.width-buttons {
    width: 30%;
}

.margin-historic {
    padding-top: 1rem;
    padding-left: 1rem;
}

.modal-header {
    padding-bottom: 0em !important;
}

.modal-body {
    padding-top: 0em !important;
}

.card-info {
    padding: 0.5rem;
    font-size: .840rem;
}

.user-card {
    padding-top: 0.1em !important;
    padding-bottom: 0.5em !important;
}

.alin-between {
    justify-content: space-between !important;
}

.hide-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
